/* Sidebar */
.sidebar {
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  color: white;
  /* padding-top: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Pseudo-element styles for specific links */
.special-link:after {
  border-left: 1px solid #ffcb05;
  bottom: 0;
  content: "";
  left: 25px;
  position: absolute;
  top: 0;
}

.special-link:before {
  background: #ffcb05;
  bottom: auto;
  content: "";
  height: 8px;
  left: 21px;
  margin-top: 0px;
  position: absolute;
  right: auto;
  width: 8px;
  z-index: 1;
  border-radius: 50%;
}

/* Ensure sidebar-link is positioned relative for pseudo-elements */
.sidebar-link {
  position: relative;
}

.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 20px; */
}

.logo {
  /* width: 100px; */
  /* height: auto; */
  /* margin-bottom: 10px; */
}

.sidebar-menu {
  width: 100%;
  margin-bottom: 20px;
  font-size: 15px;
}

.sidebar-section {
  width: 100%;
  margin-bottom: 10px;
}

.section-header {
  font-size: 12px;
  color: #ffffff;
  display: block;
  margin: 0px 0;
  padding-left: 20px;
}

.sidebar-divider {
  border: 0;
  height: 1px;
  background-color: white;
  width: 100%;
}

.sidebar-link {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.sidebar-link:hover,
.sidebar-link.active {
  background-color: #ffffff;
  color: #000000;
}

.icon {
  margin-right: 13px;
  color: #ffcc00;
  font-size: 17px;
}

.link-text {
  flex-grow: 1;
  /* margin-left: 5px; */
  /* font-size: 15px; */
}

.sidebar-dropdown {
  width: 100%;
}

.dropdown-btn {
  background: none;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  /* padding: 15px 20px; */
  padding: 13px 15px 12px 15px;

  text-align: left;
  width: 100%;
  /* font-size: 15px; */
  cursor: pointer;
}

.dropdown-btn:hover {
  background-color: #ffffff;
  color: #000000;
}

.dropdown-container {
  background-color: #272727;
  border-right: 5px solid #ffcb05;
}

.dropdown-container a {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.dropdown-container a:hover {
  background-color: #575757;
}

.sidebar-link:hover:not(.special),
.sidebar-link.active:not(.special) {
  background-color: #ffffff;
  /* border-right: 3px solid #ffcb05; */
  color: #000000;
}

/* Add a class to the special sidebar links */
.sidebar-link.special,
.sidebar-link.special:hover,
.sidebar-link.special.active {
  background-color: #272727;
  border-right: 3px solid #ffcb05;
}

