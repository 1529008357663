body {
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif !important;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-size: 13px;
}

.App {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0px;
  /* margin-left: 225px; */
  width: calc(100% - 230px);
  margin-left: auto;
}

.main-header {
  background-color: #ffcc05;
  color: #333;
  padding: 16px;
  max-height: 57px;
  display: flex;
  align-items: center;
  /* position: fixed; */
  /* top: 0; */
  /* left: 225px; */
  z-index: 1;
}

.report-title {
  border-bottom: 1px solid lightgray;
  font-size: 20px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-title {
  display: flex;
  /* align-items: center; */
}

.header-icon {
  margin-right: 20px;
  font-size: 24px;
}

.header-title h1 {
  margin: 0;
  font-size: 22px;
}

.bold-text {
  font-weight: bold;
}

.normal-text {
  font-weight: normal;
}

.user-info {
  color: #736f6f;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.profile-icon {
  margin-right: 10px;
  color: #c1b1b1;
  /* font-size: 1px; */
  height: 29px;
  border-radius: 50%;
}

.dropdown-icon {
  padding: 5px;
  font-size: 0.8rem;
}

.date-range-picker-container {
  position: relative;
  z-index: 999;
  padding-right: 60px;
}

.content {
  margin: 10px 20px 0px;
  /* margin-top: 30px; Adjusted to account for header */
  /* padding: 35px 20px; */
  flex-grow: 1;
}

.user-info::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 3px solid;
  border-right: 3px solid transparent;
  border-bottom: 0;
  border-left: 3px solid transparent;
}

.summary-report {
  /* border: 1px solid #edebeb; */
  /* background-color: #f1f1f1; */
  /* padding: 20px 25px 5px 25px; */
  /* margin-bottom: 30px; */
  /* border-radius: 8px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* width: 95%; */
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  /* padding: 10px 17px; */
  display: inline-block;
  background: #f1f1f1;
  border: 1px solid #e6e9ed;
  border-top: 3px solid #ffcb05;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  /* column-break-inside: avoid; */
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.x_title {
  border-bottom: 1px solid #dddddd;
  padding: 11px 15px 7px 15px;
  margin-bottom: 0px;
}

.x_title h2 {
  margin: 5px 0 6px;
  float: left;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

h2 {
  font-size: 18px !important;
  font-weight: 400;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

.panel_toolbox > li > a {
  padding: 5px;
  color: #c5c7cb;
  font-size: 14px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  opacity: 1 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.summary-report h2 {
  /* padding: 11px 15px 7px 15px; */
  /* font-weight: normal;
  margin-top: 0;
  margin-bottom: 10px;
  padding-bottom: 10px; */
}

.filters {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-start;
  margin-bottom: 20px;
}

.filter-group {
  margin-top: 5px;
  display: flex;
  width: 33.33%;
  flex-direction: column;
  flex: 1;
}

.filter-group.full-width {
  flex: 0 0 50%;
}

.filters label {
  margin-bottom: 5px;
  font-size: 15px;
}

.filters input,
.filters .date-picker {
  padding: 13px;
  border: 1px solid #bbb9b9;
  width: 100%;
  max-width: 250px;
}

.input-with-icon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.input-with-icon input {
  width: 100%;
  padding-right: 60px; /* Make space for the icon on the right */
}

.input-with-icon::placeholder {
  text-align: left;
}

.input-dropdown-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 1rem;
  color: #beb4b4;
}
.borderundergeneratebutton {
  margin-top: 27px;
  border-bottom: 1px solid lightgray;
}
.generate-button-container {
  margin-top: 15px;
  width: 100%;
  margin-bottom: 15px;
}

.generate-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  font-size: 15px;
  font-family: inherit;
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 3px;
  width: fit-content;
  justify-content: flex-start;
}

.generate-button:hover {
  background-color: #0056b3;
}

.table-container {
  font-size: large;
  max-width: 100%;
  overflow-x: auto;
  margin: 20px 0px;
  position: relative;
}

.report-table {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border-collapse: collapse;
}

.text-center {
  text-align: center !important;
}

.report-table th {
  border: 1px solid #ddd;
  padding: 7px;
  font-size: small;
  text-align: inherit;
}

.auto-close-popup {
  background-color: #ffffff;
  height: 180px;
  width: 570px;
}

.auto-close-title {
  color: #545454;
  font-weight: bold;
  font-size: 25px;
}

.auto-close-icon {
  font-size: small;
  margin-bottom: 0px;
  color: #28a745;
}

.saved-popup {
  width: 370px;
  padding: 0px 0px 20px;
  height: 340px;
  background-color: #ffffff;
}

.saved-title {
  color: #545454;
  font-size: 2rem;
  font-weight: 600;
}

.saved-icon {
  color: #000000;
}

.report-table td {
  color: #363636;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #e8e6e6;
  border: 1px solid #cfd3d7;
  padding: 6px 8px 6px 8px;
  font-size: 12px;
  text-align: middle;
}

.report-table th {
  background-color: #c6c6c6;
}

.report-manager {
  /* font-size: 24px; */
  font-weight: normal;
  margin: 3px 0 9px 0;
  /* margin-bottom: 10px; */
}

.grand-total {
  background-color: #ffffff;
  font-weight: bold;
}

.grand-total td {
  border: 1px solid #cfd3d7;
  padding: 7px;
  background-color: rgb(244, 241, 241);
  font-size: 13px;
  text-align: medium;
  font-weight: bold;
}

.sidebar {
  height: 100vh;
  width: 230px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.sidebar-header {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  min-height: 70px;
  /* margin-bottom: 10px; */
}

.logo {
  /* width: 120px; */
  /* height: auto; */
  /* color: #fff; */
  /* padding-top: 5px; */
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
}

.sidebar-menu {
  width: 100%;
  margin-bottom: 35px;
  font-size: 13px;
}

.sidebar-menu h3 {
  /* padding-left: 23px; */
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: 1px 1px #000;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 10px 0 10px 18px;
}

.sidebar-section {
  width: 100%;
  margin-bottom: 10px;
}

.section-header {
  font-size: 14px;
  padding: 3px 20px;
  cursor: pointer;
}

.sidebar-links {
  list-style-type: none;
  padding-left: 0;
}

.sidebar-link.dashboard {
  align-items: center;
}

.sidebar-link {
  /* display: block; */
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  padding: 13px 15px 12px 15px;
  text-decoration: none;
}

.sidebar-link:hover {
  background-color: #575757;
}

.footer {
  position: relative;
  bottom: 0;
  right: 0;
  text-align: right;
  /* padding: 0px 5px; */
  padding: 10px 20px;
  background-color: #ffffff;
  font-size: 13px;
  color: #363636;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  background: #c6c6c6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table th {
  padding: 5px 7px 5px 7px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

th {
  text-align: inherit;
}

#summary-report-data {
  overflow-x: scroll;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: transparent;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: #f1f1f1;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td {
  padding: 5px 7px 5px 7px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
  font-size: 12px !important;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0px;
}

.dropdown-btn.active {
  border-right: 5px solid #ffcb05;
  text-shadow: rgba(0, 0, 0, 0.25) 0 -1px 0;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0,
    inset rgba(255, 255, 255, 0.16) 0 1px 0;
  color: #000000 !important;
  font-weight: 600;
}

.dropdown-btn {
  margin: 0;
}

.dropdown-container a:hover {
  background-color: #494949 !important;
  color: white !important;
}

.dropdown-container {
  background: #262626 !important;
}

.swal2-actions {
  margin-top: 15px;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 1.875em !important;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.dropdown-container .current-page {
  background: rgba(255, 255, 255, 0.15) !important;
}
